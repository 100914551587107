.service-list {
    padding: 40px;
    background-color: #f9f9f9;
    text-align: center;
}

.service-list h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
}

.service-list h3 {
    font-size: 24px;
    margin-bottom: 40px;
    color: #555;
}

.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.service {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 0;
    border-radius: 10px;
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
    max-width: calc(33.333% - 20px);
    position: relative;
    overflow: hidden;
}

.service-img {
    width: 100%;
    height: auto;
    transition: filter 0.3s ease;
}

.service:hover .service-img {
    filter: blur(5px);
}

.service-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.service:hover .service-info {
    opacity: 1;
}

.service h4 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #0FA4AF;
}

.service p {
    font-size: 16px;
    color: #fff;
    margin: 0;
}
/* Responsive Styles */
@media (max-width: 1024px) {
    .service {
        flex: 1 1 calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .service {
        flex: 1 1 100%;
        max-width: 100%;
        margin: 5px;
    }

    .service-list h2 {
        font-size: 28px;
    }

    .service-list h3 {
        font-size: 20px;
    }

    .service h4 {
        font-size: 18px;
    }

    .service p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .service-list {
        padding: 20px;
    }

    .service {
        margin: 10px 0;
        border-radius: 8px;
    }

    .service-list h2 {
        font-size: 24px;
    }

    .service-list h3 {
        font-size: 18px;
    }

    .service h4 {
        font-size: 16px;
    }

    .service p {
        font-size: 13px;
    }
}
