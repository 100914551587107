/* Banner */
body{
  background-color:#f9f9f9;
}
.banner-section {
  position: relative;
}

.banner-section .banner-slider img {
  height: 520px;
  object-fit: cover;
}
.banner-content {
  position: absolute;
  top: 17%;
  width: 100%;
}

.heading {
  color: #000;
  width:auto;
  font-family: "Lexend";
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3; /* 114.286% */
  letter-spacing: -1.4px;
  text-transform: capitalize;
}
.banner-content .heading {
  color: #fff;
  line-height: 1.2;
}

.banner-content .text p {
  color: rgb(255 255 255 / 80%);
  text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.75);

  margin-bottom: 0px;
  font-size: 15px;
}

.banner-content .timer-inner p {
  text-transform: uppercase;
  color: #fff;
  font-size: 13px;
  padding-top: 6px;
  margin-bottom: 0px;
  text-align: center;
}

.banner-content .banner-book {
  width: 150px;
  margin: 0 auto;
}


/* Yatra */

.yatra-section .heading {
  font-size: 30px;
  line-height: 1.2;
}

.yatra-section .rfm-child {
  text-align: right;
  font-family: Raleway;
  font-size: 69px;
  font-weight: 800;
  line-height: 1.3;
  letter-spacing: 11.2px;
  text-transform: uppercase;
  background: linear-gradient(270deg, #0FA4AF -0.75%, #34087a 99.96%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.yatra-section .image-wrapper {
  margin-right: -15px;
}
.yatra-section .content-setion {
  padding-left: 10%;
  padding-right: 8%;
}

/* Astro help */
.astro-help .image-section {
  width: 40%;
  float: left;
}

.astro-help .content-section {
  width: 60%;
  float: left;
}

.astro-help .row-area {
  display: flex;
  align-items: center;
  flex-direction: row;
  background: #300e77;
}

.astro-help .heading {
  color: #0FA4AF;
  line-height: 1.1;
}
.astro-help p {
  color: rgb(255 255 255 / 70%);
  max-width: 550px;
}

.astro-help .content {
  padding: 0px 60px;
}

.astro-help .image-sction img {
  height: 450px;
  object-fit: cover;
}

/* notify */
.notify-inner img {
  width: 100px;
  margin: 0 auto;
}
.notify-inner .heading span {
  color: #0FA4AF;
}
.notify-inner .heading {
  line-height: 1.1;
}
.notify-inner {
  text-align: center;
}

.notify-inner .butn-default {
  background: #0FA4AF;
  color: #131313;
  border: 1.5px solid transparent;
}

.notify-inner .butn-default:hover {
  color: #fff;
}

.notify-inner .butn-default:after {
  background-color: #300e77;
}
.cost-inner {
  width: 100%;
}
.cost-wrap {

  background-position: bottom;
  padding: 214px 0;
  border-radius: 20px;
  background-size: cover;
  position: relative;
  padding-bottom: 20px;
}

.cost-wrap .content {
  margin: 0 auto;
  max-width: 822px;
  margin-top: 110px;
  text-align: center;
}
.cost-wrap .heading {
  color: #ffff;
  font-size: 40px;
  line-height: 1.1;
}
.cost-wrap p {
  color: rgb(255 255 255 / 70%);
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.kundali-section .content-setion {
  padding-left: 12px;
}
.kundali-section .image-wrapper {
  margin-left: -10%;
}

.kundali-section .heading {
  line-height: 1.1;
}

.kundali-section p {
  max-width: 600px;
}

.image-effect {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.image-effect:before {
  content: "";
  background: linear-gradient(0deg, #0FA4AF, rgb(248 249 250 / 50%));
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  transition-duration: 1.3s;
  right: 0;
  opacity: 0.7;
  top: 0;
}

.image-effect:after {
  content: "";
  background: linear-gradient(0deg, #0FA4AF, rgb(248 249 250 / 50%));
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  transition-duration: 1.3s;
  bottom: 0;
  opacity: 0.7;
  left: 0;
}
.image-effect:hover img {
  transform: scale(1.08);
}
.image-effect:hover::after,
.image-effect:hover::before {
  height: 100%;
  opacity: 0;
  width: 100%;
}
.image-effect img {
  transition: transform 0.5s ease-in-out;
}

.mystic-image {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-top: 50px;
}
.mystic-image:before {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(238, 239, 244, 0) 44.68%,
    #eeeff4 84.84%
  );
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  opacity: 0;
}

.mystic-image:hover::before {
  opacity: 1;
}
.mystic-img2 {
  margin-top: 0;
}
.mystic-content {
  text-align: center;
}

.mystic-content > span {
  color: rgba(5, 2, 29, 0.9);
  font-family: Lexend;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.mystic-wrap .content-wrap {
  position: absolute;
  padding: 20px;
  text-align: left;
  bottom: -107px;
  transition: 0.5s;
  z-index: 2;
}

.mystic-wrap .content-wrap.last-content {
  bottom: -89px;
}
.mystic-wrap .content-wrap h2 {
  color: #efe8df;
  font-size: 31px;
  font-weight: 700;
  line-height: 1.2;
  font-family: "Cambay", sans-serif;
  margin-bottom: 0px;
}

.mystic-wrap .content-wrap p {
  color: #05021d;
  font-family: Cambay;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all;
}
.mystic-image:hover .content-wrap {
  bottom: 0px;
}

.mystic-image:hover .content-wrap p {
  opacity: 1;
  visibility: visible;
}
.mystic-image:hover .content-wrap h2 {
  color: #000;
}

.mystic-img2 {
  margin-bottom: 40px;
}
.button1 {
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  font-weight: 600;
  
  width: 200px;
  padding: 10px 0;
  box-shadow: 0 0 20px #0FA4AF;
  transition: 0.4s;
}
.button1:hover {
  color: white;
  /* width:; */
  box-shadow: 0 0 20px #0FA4AF;
  background-color: #0FA4AF;
}
.anchor {
  cursor: pointer;
  border: 0;
  color: #000;
  text-align: center;
  border-radius: 4px;
  font-weight: 600;
  
  width: 200px;
  padding: 10px 0;
  box-shadow: 0 0 20px #0FA4AF;
  transition: 0.4s;
}
.anchor:hover {
  color: white;

  /* width:; */
  box-shadow: 0 0 20px #0FA4AF;
  background-color: #0FA4AF;
}

@media only screen and (min-width: 768px) {
  .yatra-section .container {
    max-width: 100%;
  }
  .kundali-section .container {
    max-width: 100%;
  }
  .banner-section .slick-slide.slick-active {
    animation: grow 3s linear forwards;
    opacity: 1;
  }

  @keyframes grow {
    0%,
    20% {
      transform: scale(1);
    }
    75%,
    100% {
      transform: scale(1.15);
    }
  }
}

@media only screen and (min-width: 1300px) {
  .banner-section .banner-slider img {
    height: 650px;
  }
  .timer-area {
    margin-top: 181px;
  }
  .kundali-section p {
    max-width: 700px;
  }
  .astro-help p {
    max-width: 750px;
  }
}
@media only screen and (max-width: 1199px) {
  .timer-area {
    width: 53%;
    margin-top: 76px;
  }
  .mystic-wrap .content-wrap h2 {
    font-size: 26px;
  }

  .heading {
    font-size: 33px;
  }

  .cost-wrap .heading {
    font-size: 33px;
  }
  .kundali-section .content-setion {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 1025px) {
  .timer-area {
    margin-top: 71px;
  }
}
@media only screen and (max-width: 1024px) {
  .heading {
    font-size: 27px;
  }

  .cost-wrap .heading {
    font-size: 27px;
  }

  .yatra-section .gradient-section {
    font-size: 56px;
  }
  .yatra-section .heading {
    font-size: 27px;
  }
}

@media only screen and (max-width: 991px) {
  .yatra-section .gradient-section {
    font-size: 47px;
  }
  .yatra-section .heading {
    font-size: 25px;
  }

  .timer-area {
    width: 73%;
    margin-top: 29px;
  }
  .banner-content .banner-book {
    width: 113px;
  }
  .banner-content .heading br {
    display: none;
  }
}

@media only screen and (max-width: 850px) and (min-width: 768px) {
  .timer-area {
    margin-top: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .yatra-section .heading {
    font-size: 20px;
  }

  .heading {
    font-size: 25px;
  }
  .cost-wrap {
    padding: 75px 20px;
  }
}

@media only screen and (max-width: 767.5px) {
  .yatra-section .gradient-section {
    font-size: 40px;
  }

  .yatra-section .image-wrapper {
    margin-right: 0;
  }
  .yatra-section .gradient-section {
    margin-right: 0;
    text-align: center;
  }
  .yatra-section .content-setion {
    padding-left: 0;
    padding-right: 0;
  }

  .mystic-image {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .yatra-section img,
  .kundali-section img {
    border-radius: 20px;
  }

  .banner-content {
    top: 6%;
  }

  .timer-area {
    width: 100%;
    margin-top: -16px;
  }

  .banner-section .slick-slide img {
    height: 450px;
    object-fit: cover;
  }

  .mystic-wrap .content-wrap {
    bottom: -63px;
  }
  .mystic-wrap .content-wrap.last-content {
    bottom: -63px;
  }
  .kundali-section .image-wrapper {
    margin-left: 0;
  }

  .kundali-section .content-setion {
    padding: 20px 0px 0px 0px;
  }

  .section {
    padding: 20px 0px;
  }

  .astro-help .content {
    padding: 0px 20px;
  }
  .footer-social ul {
    justify-content: center;
  }

  .footer-logo {
    margin: 0 auto;
    margin-bottom: 10px;
  }

  footer.footer {
    padding: 0px 0px;
    padding-bottom: 10px;
  }
  .yatra-section .rfm-child {
    font-size: 42px;
  }
}
@media only screen and (max-width: 600px) {
  .mystic-image {
    height: 350px;
  }
  .mystic-image img {
    height: 100%;
    object-fit: cover;
  }

  .mystic-wrap .content-wrap {
    bottom: -77px;
  }
  .yatra-section .rfm-child {
    font-size: 29px;
  }
  .timer-content p {
    font-size: 15px;
  }
  .banner-section .banner-slider img {
    height: 450px;
  }
}
@media only screen and (max-width: 576px) {
  .cost-wrap .heading {
    font-size: 22px;
    line-height: 1.2;
  }
  .heading {
    font-size: 22px;
    line-height: 1.2;
  }
  .kundali-section .heading {
    line-height: 1.2;
  }

  .astro-help .image-section,
  .astro-help .content-section {
    width: 100%;
    float: none;
  }

  .astro-help .row-area {
    flex-direction: column-reverse;
    margin: 0px 15px;
  }
  .astro-help .content {
    padding: 21px 20px 0px 16px;
  }

  .notify-inner .heading br {
    display: none;
  }
  .banner-content .banner-book {
    width: 91px;
  }
  .mystic-image {
    height: 300px;
  }
  .banner-content .heading {
    font-size: 34px;
  }
  .banner-content .banner-book img {
    margin-left: -24px;
  }
}

@media only screen and (max-width: 481px) and (min-width: 350px) {
  .banner-content .banner-book {
    width: 95px;
  }
}

@media only screen and (max-width: 480px) {
  .cost-wrap {
    padding: 20px 10px;
  }
  .cost-wrap .content {
    margin-top: 0;
  }
  .timer-inner span {
    font-size: 18px;
  }

  .timer-inner .digit-box {
    padding: 3px 9px;
    margin-right: 1px;
  }

  .yatra-section .heading,
  .notify-inner .heading,
  .kundali-section .heading {
    line-height: 1.2;
  }
  .timer-inner span.empty {
    padding: 0px 3px;
    margin-top: 22px;
  }

  .banner-content .heading {
    font-size: 25px;
  }
  .timer-area {
    margin-top: 0;
  }
  .yatra-section .gradient-section {
    font-size: 30px;
  }
  .astro-help .image-sction img {
    height: 300px;
  }
  .banner-content {
    top: 3%;
  }
  .timer-content p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 400px) {
  .banner-content .text p {
    font-size: 14px;
  }
  .yatra-section.section {
    margin-top: 60px;
  }
  .mystic-image:hover .content-wrap.last-content {
    bottom: -26px;
  }
}
@media only screen and (max-width: 380px) {
  .timer-area {
    margin-top: 14px;
  }
  .banner-content {
    top: 5%;
  }
  .banner-content .heading {
    font-size: 23px;
  }
  .timer-content p {
    font-size: 11px;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
