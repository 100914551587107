.love-us {
    padding: 40px;
    background-color: #f9f9f9;
    text-align: center;
}

.love-us h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
}

.reasons-slider {
    position: relative;
    overflow: hidden;
}

.reasons {
    display: flex;
    transition: transform 0.5s ease;
}

.reason {
    flex: 0 0 calc(100% / 3); /* 3 items on desktop */
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.reason img {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.reason h4 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #0FA4AF;
}

.reason p {
    font-size: 16px;
    color: #777;
}

.prev-btn,
.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.prev-btn:hover,
.next-btn:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .reason {
        flex: 0 0 calc(100% / 2); /* 2 items on tablets */
    }

    .love-us h2 {
        font-size: 28px;
    }
}

@media (max-width: 768px) {
    .reason {
        flex: 0 0 100%; /* 1 item on mobile */
    }

    .love-us h2 {
        font-size: 24px;
    }

    .prev-btn,
    .next-btn {
        padding: 8px 12px;
    }
}
