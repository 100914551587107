.about-us-container1 {
  margin-top: 7%;
    width: 100%;
    font-family: Arial, sans-serif;
    color: aliceblue;
    
  }
  
  
  .about-us-banner img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  
  .company-overview{
    
    display: inline-block;
   
  }
  
  .company-overview h2 {
    font-size: 36px;
  }
  
  .company-overview p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .animated-images {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
  }
  
  .animated-image {
    width: 300px;
    height: auto;
  }
  
  .mission-vision-values h3 {
    font-size: 28px;
    margin-top: 20px;
    color: #333;
  }
  
  .mission-vision-values p, .mission-vision-values ul {
    font-size: 18px;
    line-height: 1.6;
    
    margin-top: 10px;
  }
  
  .mission-vision-values ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  .team-container {
    position: relative;
   
    text-align: center;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.5; /* Adjust opacity as needed */
  }
  
  .team-member {
    /* position: relative;
    z-index: 1; */
    /* Other styling */
  }
  

  
  
  
  .team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .team-member {
    background: #0FA4AF;
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 250px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  
  .team-member-image {
    width: 100%;
    height: auto;
    
    margin-bottom: 15px;
  }
  
  .team-member-name {
    font-size: 24px;
    margin: 10px 0;
    color: aliceblue;
  }
  
  .team-member-position {
    font-size: 18px;
    color: #333;
  }
  
  .team-member-description {
    font-size: 16px;
    color: aliceblue;
    line-height: 1.5;
  }


  
    .containerA {
      position: relative;

      background-size: cover;
      background-position: center;
      height:100vh;
      display: flex;
      justify-content: space-between;
      margin-top: -100px; /* Adjust this value based on how much you want the image to overflow into the header */
      z-index: -1; /* Ensure it stays behind other content */
      overflow: visible;
}
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box1{
border-style: outset;
border-color: #0FA4AF; 
background-color: transparent; 
width: 25%;
height: 300px;
position: absolute;
left: -25px;
top:50%;
transform: translateY(-50%);
animation: hamza1;
animation-duration: 3s;
animation-iteration-count: 1;
}
.containerA img{
    height: 300px;
    width: 25%;
    position: absolute;
    
    left: calc(250px - 15%);
    top: 60%;
    transform: translateY(-40%);
}
.team-title {
  font-family: "Aldrich", sans-serif;
  font-size: 4.6vw;
  font-weight: 600;
  text-align: center;
 
  background: #333;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.MPara{
  margin-top: 10em;
  color: #FFFFFF;
    font-family: "Arial Black", Sans-serif;
   
    font-weight: 900;
    
   
}
.para{
  position: relative;
  width: 50%;
  margin-left: 40%;
  padding-top: 10%; /* Align the content in the middle */
  margin-right: auto;
  text-align: center;
  
}

.para h2{
  color: #FFFFFF;
    font-family: "Aldrich", Sans-serif;
    font-size: 4.5vw;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 6vw;
    letter-spacing: 3px;
}
@keyframes hamza1{
    from{
        left:100px;
    }
    to{
        right:100px;
    }
}

.container2{
  position: relative;
  
  height: 300px;
  display: flexbox;
  justify-content: space-between ;
  margin-bottom: 5%;
  margin-left: 10%;
  margin-right: 10%;

}

.box2 {
  border-style: outset;
  border-color: #333; 
background-color: transparent; 
  width: 25%;
  height: 80%;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.container2 img {
  height: 80%;
  width: 25%;
  position: absolute;
  left: calc(250px - 15%);
  top: 60%;
  transform: translateY(-40%);
}

.para2 {
  color: #FFFFFF;
    font-family: "Arial Black", Sans-serif;
   
    font-weight: 900;
  position: relative;
  width: 50%;
  margin-left: 40%;
  padding-top: 10%; /* Align the content in the middle */
  margin-right: auto;
  text-align: center;
}

/* @keyframes box2 {
  from {
    left: 100px;
  }
  to {
    right: 100px;
  }
} */

.animate {
  animation: box2 3s 1;
}

.container3 {
  position: relative;
  height: 300px;
  display: flexbox;
  justify-content: space-between;
  margin-bottom: 5%;
  margin-left: 10%;
  margin-right: 10%;
}

.box3 {
  border-style: outset;
  border-color: #333; 
background-color: transparent; 
  width: 25%;
  height: 80%;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.container3 img {
  height: 80%;
  width: 25%;
  position: absolute;
  right: calc(250px - 15%);
  top: 60%;
  transform: translateY(-40%);
}

.para3 {
  color: #FFFFFF;
    font-family: "Arial Black", Sans-serif;
   
    font-weight: 900;
  position: relative;
  width: 50%;
  margin-right: 40%;
  padding-top: 10%; /* Align the content in the middle */
  margin-left: auto;
  text-align: center;
}

.backgroundChange{
  background-color: #0FA4AF;
}
.MobileBackgroundChange{
  margin-top: 100px;
}
@media (max-width: 768px) {
  .company-overview h2 {
    font-size: 28px;
  }
  .company-overview p {
    font-size: 16px;
  }
  .team-member {
    max-width: 100%;
    margin: 10px;
  }
  .para, .para2, .para3 {
    width: 80%;
    margin: 0 auto;
    padding-top: 5%;
    color: #FFFFFF;
    font-family: "Gill Sans", sans-serif;
    font-weight: 600;
  }
  .container3 img {
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    right:0.1px;
    top: 40%;
    transform: translateY(-40%);
    opacity: 0.5;
  }
  .container2 img {
    display: none;
    height: 80%;
    width: 25%;
    position: absolute;
    left: calc(250px - 15%);
    top: 60%;
    transform: translateY(-40%);
  }
  .team-member-image {
    width: 70%;
    height: 50%;
    border-radius: 80%;
    margin-bottom: 15px;
  }
}
