.chatbot {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.chatbot-button {
    background-color: #0FA4AF;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
}

.chatbot-window {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 300px;
    height: 400px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.chatbot-header {
    padding: 10px;
    background-color: #0FA4AF;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatbot-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f5f5f5;
}

.chatbot-message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.chatbot-message.user {
    background-color: #0FA4AF;
    color: white;
    align-self: flex-end;
}

.chatbot-message.bot {
    background-color: #e0e0e0;
    color: black;
    align-self: flex-start;
}

.chatbot-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.chatbot-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
}

.chatbot-input button {
    padding: 10px 20px;
    background-color: #0FA4AF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.chatbot-close {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
  }
