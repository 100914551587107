.timer-wrap {
    display: none;
  }
  .timer-main{
    margin-top:-3%;
    position:relative;
    width:45%;
    margin-left: 30%;
  }
  
  .timer-content {
    background: #34087a;
    text-align: center;
    padding: 10px 0;
    border-radius: 10px 10px 0 0;
    width: 78%;
    margin: 0 auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  
  .timer-content span {
    color: #0fa4af;
    font-family: "Lexend", sans-serif;
  }
  
  .timer-content p {
    color: #fff;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1.04px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  
  .timer-area {
    width: 47%;
    margin: 72px auto 0;
  }
  
  .timer-inner {
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 20px 17px;
    background: #0fa4af;
    border-radius: 40px 40px 0 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  
  .timer-inner span {
    color: #000;
    font-size: 26px;
    font-weight: 400;
    font-family: "arial", sans-serif;
    display: inline-flex;
  }
  
  .timer-inner span.empty {
    color: #fff;
    align-items: flex-end;
    padding: 0 10px;
    margin-top: 10px;
  }
  
  .timer-inner .digit-box {
    background: #fff;
    padding: 6px 13px;
    border-radius: 7px;
    margin-right: 6px;
  }
  
  .section {
    padding: 30px 0;
  }
  .timer-anchor {
    cursor: pointer;
    border: 0;
    color: #000;
    text-align: center;
    border-radius: 4px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 0 20px #0FA4AF;
    transition: 0.4s;
  }
  .timer-anchor:hover {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    /* width:; */
    box-shadow: 0 0 20px #0FA4AF;
    background-color: #0FA4AF;
  }
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .timer-main{
        margin-top:4%;
        position:relative;
        width:100%;
        margin-left: 0%;
      }
      
    .timer-area {
      width: 80%;
      margin-top: 50px;
    }
  
    .timer-content {
      width: 80%;
      padding: 8px 0;
    }
  
    .timer-inner {
        display: flex;
      font-size: 32px;
      padding: 15px 10px;
      border-radius: 40px 40px 0 0;
    }
  
    .timer-inner span {
      font-size: 20px;
    }
  
    .timer-inner .digit-box {
      padding: 5px 10px;
      margin-right: 4px;
    }
  
    .anchor {
      padding: 8px 15px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .timer-area {
      width: 95%;
      margin-top: 40px;
    }
  
    .timer-inner {
      
      align-items: center;
      font-size: 28px;
    }
  
    .timer-inner img {
      width: 50px;
      margin-bottom: 10px;
    }
  
    .timer-inner .digit-box {
      padding: 4px 8px;
      margin-right: 2px;
    }
  
    .anchor {
      padding: 7px 12px;
      font-size: 12px;
    }
  
    .timer-content p {
      font-size: 14px;
    }
  }
  