.banner {
  position: relative;
  width: 100%;
  max-height: 1200px;
  overflow: hidden;
}

.banner-image img {
  width: 100%;
  height: auto;
  max-height: 1200px;
  object-fit: cover;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Black tint with transparency */
  border-radius: 10px;
  max-width: 80%;
}

.banner-title {
  font-size: 36px;
  margin-bottom: 20px;
  animation: slideInLeft 1s ease-in-out;
}

.banner-subtitle {
  font-size: 24px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.3); /* Black tint with transparency */
  padding: 10px;
  border-radius: 5px;
  animation: slideInRight 1s ease-in-out;
}

.banner-text {
  font-size: 18px;
  animation: fadeIn 2s ease-in-out;
}

/* Animation Keyframes */
@keyframes slideInLeft {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInRight {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .banner-title {
      font-size: 32px;
  }

  .banner-subtitle {
      font-size: 20px;
  }

  .banner-text {
      font-size: 16px;
  }
}

@media (max-width: 768px) {
  .banner-content {
      padding: 15px;
      max-width: 90%;
  }

  .banner-title {
      font-size: 28px;
  }

  .banner-subtitle {
      font-size: 18px;
  }

  .banner-text {
      font-size: 15px;
  }
}

@media (max-width: 480px) {
  .banner-title {
      font-size: 24px;
  }

  .banner-subtitle {
      font-size: 16px;
  }

  .banner-text {
      font-size: 14px;
  }
}
