.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
    text-align: left;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    flex: 1;
    margin: 10px;
    min-width: 200px;
}

.footer-section h4 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
}

.footer-section p,
.footer-section ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-section ul li a:hover {
    color: #ddd;
}

.social-icons {
    margin-top: 20px;
}

.social-icons a {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #ddd;
}

.footer-bottom {
    background-color: #222;
    color: #bbb;
    text-align: center;
    padding: 10px 0;
    margin-top: 20px;
}

.footer-bottom p {
    margin: 0;
}
